import React from 'react'
import SearchWidget from '../../widgets/search'
import RecentPostWidget from '../../widgets/recent-post'
import CategoriesWidget from '../../widgets/categories'
import {SidebarWrap} from './sidebar.stc'

const Sidebar = ( {categoriesSummary, recentPosts } ) => {
    return (
        <SidebarWrap>
            <SearchWidget/>
            <RecentPostWidget recentPosts={recentPosts}/>
            <CategoriesWidget categoriesSummary={categoriesSummary}/>
        </SidebarWrap>
    )
}

Sidebar.defaultProps = {
    categoriesSummary: {},
    recentPosts: [],
};

export default Sidebar
 