import React from 'react'
import WidgetBox, { WidgetTitle } from '../../../components/shared/widget-box'
import Blog from '../../../components/blog/layout-three'
import { truncateString } from '../../../utils/utilFunctions'
import { FeaturePostWrap, FeaturePostItem } from './feature-posts.stc'

const FeaturePosts = ({ blogStyles, widgetStyle, featurePosts }) => {
    const blogs = featurePosts;
    return (
        <WidgetBox {...widgetStyle} id="feature-post">
            <WidgetTitle>Featured Posts</WidgetTitle>
            <FeaturePostWrap>
                {blogs.map(blog => (
                    <FeaturePostItem key={blog.node.fields.slug}>
                        <Blog
                            {...blogStyles}
                            content={{
                                ...blog.node.fields,
                                ...blog.node.frontmatter,
                                title: truncateString(blog.node.frontmatter.title, 30)
                            }}
                        />
                    </FeaturePostItem>
                ))}
            </FeaturePostWrap>
        </WidgetBox>
    )
}

FeaturePosts.defaultProps = {
    widgetStyle: {
        bg: 'themeBackgroundColor',
        color: '#fff',
        border: 'none'
    },
    blogStyles: {
        boxCSS: {
            layout: 2
        },
        titleCSS: {
            color: '#fff'
        },
        dateCSS: {
            color: '#fff'
        }
    },
    featurePosts: {
 
    },
}

export default FeaturePosts
